@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./css/fonts.css";
@import "./css/btns.css";
@import "./css/xinputs.css";
@import "./css/inputs.css";
@import"./css/selectinputs.css";
@import "./css/table.css";
@import "./css/headers.css";

.linkHeader {
    font-family: Ageo;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.015em;
}

.modal-shadow {
    box-shadow:
        0px 0px 5px rgba(0, 0, 0, 0.11),
        0px 25px 35px rgba(0, 0, 0, 0.08);
}

.multiselect__input,
.multiselect__input:focus {
    @apply shadow-none ring-0 border-0 outline-none;
}

.word-break {
    word-wrap: break-word;
}

.login {
    background-image: url("./assets/images/png/app/FondoLogin.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center
}

@media (max-height: 1000px) {
    .login {
        background-image: none;
    }
}