input {
    @apply form-input;
}

input.inp-large {
    font-size: 17px;
    border-radius: 16px;
}

input.inp-large_empty {
    padding-top: 18px;
    padding-bottom: 18px;
}

input.inp-large_notempty {
    padding-top: 29px;
    padding-bottom: 7px;
}

input.inp-small {
    font-size: 15px;
    border-radius: 16px;
}

input.inp-small_empty {
    padding-top: 15px;
    padding-bottom: 14px;
}

input.inp-small_notempty {
    padding-top: 26px;
    padding-bottom: 3px;
}

input.inp-small_label {
    font-family: Ageo;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    top: 4px;
    @apply absolute;
}

input[type="number"].inp-number::-webkit-outer-spin-button,
input[type="number"].inp-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.inp-mini {
    font-size: 15px;
    border-radius: 16px;
}

input.inp-mini_empty {
    padding-top: 5px;
    padding-bottom: 5px;
}

input.inp-mini_notempty {
    padding-top: 10px;
    padding-bottom: 0px;
}

input.inp-mini_label {
    font-family: Ageo;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    top: 0px;
    @apply absolute;
}

input.inp-standard {
    font-family: Ageo;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    box-sizing: border-box;
    @apply text-darkgray bg-semigray placeholder-darkgray outline-none;
}

input.inp-standard:disabled {
    @apply bg-mediumgray;
}